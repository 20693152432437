<template>
    <div>
        <div class="searchline">
            <el-date-picker v-model="date" type="date" size="small" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd" style="width:190px;">
            </el-date-picker>
            <el-button class="mr20" size="small" type="primary" @click="query">查询</el-button>
        </div>
        <!-- <el-card>
            <el-row :gutter="15">
                <el-col :span="16">
                    <div class="k"> 注册用户数：{{num}}</div>
                </el-col>
                <el-col :span="8">
                    <div class="k"> 注册总量：{{signupallnum}}</div>
                </el-col>
                <el-col :span="4">
                    <div class="k"> 下载量：{{downloadnum}}</div>
                </el-col>
                <el-col :span="16">
                    <div class="k">下载总量：{{downloadallnum}}</div>
                </el-col>
                <el-col :span="4">
                    <div class="k">报名用户数：{{signupnum}}</div>
                </el-col>
            </el-row>
        </el-card> -->
        <div class="kmain">
            <div class="ck one">
                <p class="ky colorone">
                    <img src="../assets/xzzl.png" alt="">
                </p>
                <p class="ktitle">下载总量：</p>
                <p class="kname">{{downloadallnum}}</p>
            </div>
            <div class="ck two">
                <p class="ky colortwo">
                    <img src="../assets/zczl.png" alt="">
                </p>
                <p class="ktitle">注册总量：</p>
                <p class="kname">{{signupallnum}}</p>
            </div>
            <div class="ck three">
                <p class="ky  colorthree">
                    <img src="../assets/xz.png" alt="">
                </p>
                <p class="ktitle">下载量：</p>
                <p class="kname">{{downloadnum}}</p>
            </div>
            <div class="ck four">
                <p class="ky  colorfour">
                    <img src="../assets/zc.png" alt="">
                </p>
                <p class="ktitle">注册用户数：</p>
                <p class="kname">{{num}}</p>
            </div>
            <div class="ck five">
                <p class="ky  colorfive">
                    <img src="../assets/bm.png" alt="">
                </p>
                <p class="ktitle">报名用户数：</p>
                <p class="kname">{{signupnum}}</p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        inject: ['reload'],
        data() {
            return {
                date: '',
                num: '0',
                downloadnum: '0',
                signupnum: '0',
                signupallnum: '0',
                downloadallnum: '0',
            }
        },
        mounted() {
            this.axios.get('/report/get_gu_st_down_all')
                .then((res) => {
                    // console.log(res)
                    if (res.data.code == 0) {
                        this.downloadallnum = res.data.result.count;
                    } else {
                        this.downloadallnum = '0';
                        console.log(res);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            this.axios.get('/report/user_count_date_all')
                .then((res) => {
                    // console.log(res)
                    if (res.data.code == 0) {
                        this.signupallnum = res.data.result;
                    } else {
                        this.signupallnum = '0'
                        console.log(res);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        methods: {
            query() {
                this.axios.get('/report/user_count_date?date=' + this.date)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.num = res.data.result;
                        } else {
                            this.num = '0';
                            console.log(res);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                this.axios.get('/report/get_gu_st_down?date=' + this.date)
                    .then((res) => {
                        if (res.data.code == 0) {
                            if (res.data.result != null) {
                                this.downloadnum = res.data.result.count;
                            } else {
                                this.downloadnum = '0';
                            }
                        } else {
                            console.log(res);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                this.axios.get('/report/get_gu_ill?date=' + this.date)
                    .then((res) => {
                        if (res.data.code == 0) {
                            if (res.data.result != null) {
                                this.signupnum = res.data.result;
                            } else {
                                this.signupnum = '0';
                            }
                        } else {
                            console.log(res);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    }
</script>
<style>
    .searchline {
        width: 100%;
        height: 50px;
        text-align: left;
        line-height: 50px;
    }

    .mr20 {
        margin-left: 10px;
    }

    .k {
        background: rgb(83, 168, 255);
        font-size: 24px;
        color: #fff;
        height: 60px;
        line-height: 60px;
        border-radius: 4px 4px 4px 4px !important;
        padding: 15px 20px;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .kmain {
        width: 100%;
        height: 200px;
        margin-top: 10px;
    }

    .ck {
        /* width: 210px; */
        width: 18%;
        height: 185px;
        margin-right: 15px;
        float: left;
        background-color: aqua;
        -webkit-box-shadow:0 2px 12px 0 rgba(0, 0, 0, 0.1);
        -moz-box-shadow:0 2px 12px 0 rgba(0, 0, 0, 0.1);
        box-shadow:0 2px 12px 0 rgba(0, 0, 0, 0.1);
        background-size: cover;
        padding: 20px 14px;
        box-sizing: border-box;
        border-radius: 6px;
    }

    .ck p {
        margin: 0;
        text-align: left;
    }

    .one {
        background-image: url('../assets/onebg.jpg');
    }

    .two {
        background-image: url('../assets/twobg.jpg');
    }

    .three {
        background-image: url('../assets/threebg.jpg');
    }

    .four {
        background-image: url('../assets/fourbg.jpg');
    }

    .five {
        background-image: url('../assets/fivebg.jpg');
    }

    .ck .ktitle {
        font-size: 14px;
        margin-bottom: 4px;
        color: #bcbcbc;
    }

    .kname {
        font-size: 18px;
        color: #5a5a5a;
    }

    .ck .ky {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        float: left;
        margin-right: 10px;
        padding: 8px;
        box-sizing: border-box;
    }
    .colorone{
        background: #fd9f33;
    }
    .colortwo{
        background: #3a3a3a;
    }
    .colorthree{
        background: #249be5;
    }
    .colorfour{
        background: #4cca44;
    }
    .colorfive{
        background: #989898;
    }
</style>